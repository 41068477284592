import React from 'react'
import { Navbar } from '../Components/Navbar/Navbar'
import { Footer } from '../Components/Footer/Footer'
import BlogContent from '../Components/Blog/BlogContent'

const Careers = () => {
    return (
        <div style={{overflowX:'hidden'}}>
            <Navbar />
            <BlogContent />
            <Footer />
        </div>
    )
}

export default Careers