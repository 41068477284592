import React from 'react'
import Mvp from '../../assets/images/mvp11.png'
import M from '../../assets/images/mvpimg.png'

const Mvpdev = () => {
    return (
        <div className='mvpcontent'>
            <div className='mvpbanner'>
                <img src={Mvp} alt='banner' className='w-100 bannr-img' />
                <div className='mvpcontentx'>
                    <p className='h1'>MVP Development</p>
                    <p>MVP, or Minimum Viable Product, development is a crucial phase in the product development lifecycle. It involves creating a simplified version of a product with the minimum features required to meet the needs of early adopters and gather valuable feedback. Here's a general outline for content related to MVP development on a website.</p>
                </div>
            </div>
            <div className='container'>
                <div className='row my-lg-5 my-2 mt-4'>
                    <div className='col-md-6 order-md-2 mvpblockimg' data-aos="fade-up" data-aos-delay="300">
                        {/* <div className='mvpblockimg'> */}
                        <img src={M} alt="mvpblocks" className='mvp-img' />
                        {/* </div> */}
                    </div>
                    <div className='col-md-6 order-md-1' data-aos="fade-up" data-aos-delay="300">
                        <div className='d-flex'>
                            <div className='sticker'>
                                <p className='number m-0'>1</p>
                            </div>
                            <div className=''>
                                <p className='intro h1 font-bold mb-4'>Introduction</p>
                                <ul className='mvplist'>
                                    <li className='mb-4'>Briefly explain what an MVP is and its significance in product development.</li>
                                    <li className='mb-4'>Highlight the purpose of building an MVP, focusing on iterative development and customer feedback.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container'>
                <div className='row my-lg-5 my-2'>
                    <div className='col-md-6  mvpblockimg1' data-aos="fade-up" data-aos-delay="300">
                        {/* <div className='mvpblockimg'> */}
                        <img src={M} alt="mvpblocks" className='mvp-img' />
                        {/* </div> */}
                    </div>
                    <div className='col-md-6' data-aos="fade-up" data-aos-delay="300">
                        <div className='d-flex'>
                            <div className='sticker'>
                                <p className='number m-0'>2</p>
                            </div>
                            <div className=''>
                                <p className='intro h1 font-bold mb-4'>Why MVP Development</p>
                                <ul className='mvplist'>
                                    <li className='mb-4'>Discuss the benefits of MVP development, such as cost-effectiveness, reduced time to market, and risk mitigation.</li>
                                    <li className='mb-4'>Emphasize the importance of learning from user feedback to enhance the final product.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container'>
                <div className='row my-lg-5 my-2'>
                    <div className='col-md-6 order-md-2 mvpblockimg' data-aos="fade-up" data-aos-delay="300">
                        {/* <div className='mvpblockimg'> */}
                        <img src={M} alt="mvpblocks" className='mvp-img' />
                        {/* </div> */}
                    </div>
                    <div className='col-md-6 order-md-1' data-aos="fade-up" data-aos-delay="300">
                        <div className='d-flex'>
                            <div className='sticker'>
                                <p className='number m-0'>3</p>
                            </div>
                            <div className=''>
                                <p className='intro h1 font-bold mb-4'>Key Steps in MVP Development</p>
                                <ul className='mvplist'>
                                    <li className='mb-4'>Outline the step-by-step process of developing an MVP, including ideation, defining features, prototyping, development, testing, and iteration.</li>
                                    <li className='mb-4'>Provide insights into how to prioritize features based on user needs and market trends.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container'>
                <div className='row my-lg-5 my-2'>
                    <div className='col-md-6  mvpblockimg1' data-aos="fade-up" data-aos-delay="300">
                        {/* <div className='mvpblockimg'> */}
                        <img src={M} alt="mvpblocks" className='mvp-img' />
                        {/* </div> */}
                    </div>
                    <div className='col-md-6' data-aos="fade-up" data-aos-delay="300">
                        <div className='d-flex'>
                            <div className='sticker'>
                                <p className='number m-0'>4</p>
                            </div>
                            <div className=''>
                                <p className='intro h1 font-bold mb-4'>Identifying the Target Audience</p>
                                <ul className='mvplist'>
                                    <li className='mb-4'>Discuss the significance of identifying the target audience for the MVP.</li>
                                    <li className='mb-4'>Explain how understanding the needs and preferences of the target audience helps in refining the MVP features.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container'>
                <div className='row my-lg-5 my-2'>
                    <div className='col-md-6 order-md-2 mvpblockimg' data-aos="fade-up" data-aos-delay="300">
                        {/* <div className='mvpblockimg'> */}
                        <img src={M} alt="mvpblocks" className='mvp-img' />
                        {/* </div> */}
                    </div>
                    <div className='col-md-6 order-md-1' data-aos="fade-up" data-aos-delay="300">
                        <div className='d-flex'>
                            <div className='sticker'>
                                <p className='number m-0'>5</p>
                            </div>
                            <div className=''>
                                <p className='intro h1 font-bold mb-4'>Building a Prototype</p>
                                <ul className='mvplist'>
                                    <li className='mb-4'>Emphasize the role of prototyping in visualizing the product concept and gathering early feedback.</li>
                                    <li className='mb-4'>Highlight tools and methodologies for creating effective prototypes.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container'>
                <div className='row my-lg-5 my-2'>
                    <div className='col-md-6  mvpblockimg1' data-aos="fade-up" data-aos-delay="300">
                        {/* <div className='mvpblockimg'> */}
                        <img src={M} alt="mvpblocks" className='mvp-img' />
                        {/* </div> */}
                    </div>
                    <div className='col-md-6' data-aos="fade-up" data-aos-delay="300">
                        <div className='d-flex'>
                            <div className='sticker'>
                                <p className='number m-0'>6</p>
                            </div>
                            <div className=''>
                                <p className='intro h1 font-bold mb-4'>MVP Features and Functionality</p>
                                <ul className='mvplist'>
                                    <li className='mb-4'>List and describe the essential features included in the MVP.</li>
                                    <li className='mb-4'>Explain how these features address the core problem or need of the target audience.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container'>
                <div className='row my-lg-5 my-2'>
                    <div className='col-md-6 order-md-2 mvpblockimg' data-aos="fade-up" data-aos-delay="300">
                        {/* <div className='mvpblockimg'> */}
                        <img src={M} alt="mvpblocks" className='mvp-img' />
                        {/* </div> */}
                    </div>
                    <div className='col-md-6 order-md-1' data-aos="fade-up" data-aos-delay="300">
                        <div className='d-flex'>
                            <div className='sticker'>
                                <p className='number m-0'>7</p>
                            </div>
                            <div className=''>
                                <p className='intro h1 font-bold mb-4'>User Feedback and Iteration</p>
                                <ul className='mvplist'>
                                    <li className='mb-4'>Discuss the importance of actively seeking user feedback during and after MVP launch.</li>
                                    <li className='mb-4'>Explain how iteration based on user feedback contributes to product improvement.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container'>
                <div className='row my-lg-5 my-2'>
                    <div className='col-md-6  mvpblockimg1' data-aos="fade-up" data-aos-delay="300">
                        {/* <div className='mvpblockimg'> */}
                        <img src={M} alt="mvpblocks" className='mvp-img' />
                        {/* </div> */}
                    </div>
                    <div className='col-md-6' data-aos="fade-up" data-aos-delay="300">
                        <div className='d-flex'>
                            <div className='sticker'>
                                <p className='number m-0'>8</p>
                            </div>
                            <div className=''>
                                <p className='intro h1 font-bold mb-4'>MVP Case Studies</p>
                                <ul className='mvplist'>
                                    <li className='mb-4'>Showcase real-world examples of successful MVPs, demonstrating how they evolved into fully-fledged products.</li>
                                    <li className='mb-4'>Include metrics like user adoption, feedback incorporation, and subsequent feature additions.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container'>
                <div className='row my-lg-5 my-2'>
                    <div className='col-md-6 order-md-2 mvpblockimg' data-aos="fade-up" data-aos-delay="300">
                        {/* <div className='mvpblockimg'> */}
                        <img src={M} alt="mvpblocks" className='mvp-img' />
                        {/* </div> */}
                    </div>
                    <div className='col-md-6 order-md-1' data-aos="fade-up" data-aos-delay="300">
                        <div className='d-flex'>
                            <div className='sticker'>
                                <p className='number m-0'>9</p>
                            </div>
                            <div className=''>
                                <p className='intro h1 font-bold mb-4'>Tools and Technologies</p>
                                <ul className='mvplist'>
                                    <li className='mb-4'>Provide information on the tools and technologies commonly used in MVP development.</li>
                                    <li className='mb-4'>Offer recommendations for development frameworks, prototyping tools, and analytics platforms.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container'>
                <div className='row my-lg-5 my-2'>
                    <div className='col-md-6  mvpblockimg1' data-aos="fade-up" data-aos-delay="300">
                        {/* <div className='mvpblockimg'> */}
                        <img src={M} alt="mvpblocks" className='mvp-img' />
                        {/* </div> */}
                    </div>
                    <div className='col-md-6' data-aos="fade-up" data-aos-delay="300">
                        <div className='d-flex'>
                            <div className='sticker'>
                                <p className='number m-0'>10</p>
                            </div>
                            <div className=''>
                                <p className='intro h1 font-bold mb-4'>Getting Started</p>
                                <ul className='mvplist'>
                                    <li className='mb-4'>Offer practical tips for entrepreneurs and product managers looking to start their MVP development journey.</li>
                                    <li className='mb-4'>Provide resources, templates, and guides for effective MVP planning.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container'>
                <div className='row my-lg-5 my-2'>
                    <div className='col-md-6 order-md-2 mvpblockimg' data-aos="fade-up" data-aos-delay="300">
                        {/* <div className='mvpblockimg'> */}
                        <img src={M} alt="mvpblocks" className='mvp-img' />
                        {/* </div> */}
                    </div>
                    <div className='col-md-6 order-md-1' data-aos="fade-up" data-aos-delay="300">
                        <div className='d-flex'>
                            <div className='sticker'>
                                <p className='number m-0'>11</p>
                            </div>
                            <div className=''>
                                <p className='intro h1 font-bold mb-4'>FAQs</p>
                                <ul className='mvplist'>
                                    <li className='mb-4'>Address common questions related to MVP development, such as cost considerations, timeline, and scalability.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Mvpdev