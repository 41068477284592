import React from 'react'
import B1 from '../../assets/images/hand.webp'
import C1 from '../../assets/images/com.webp'
import X1 from '../../assets/images/x1.webp'
import X2 from '../../assets/images/x2.webp'
import Q1 from '../../assets/images/tag.webp'
import Q2 from '../../assets/images/cal.webp'
import Q3 from '../../assets/images/use.webp'
import R1 from '../../assets/images/r1.webp'
import R2 from '../../assets/images/r2.webp'
import R3 from '../../assets/images/r3.webp'
import R4 from '../../assets/images/r4.webp'
import R5 from '../../assets/images/r5.webp'
import { Link } from 'react-router-dom'

const BlogContent = () => {

    const Recent = [
        {
            image: R1,
            head: "Going all-in with millennial design",
            date: "03 Aug 2022"
        },
        {
            image: R2,
            head: "Exploring new ways of decorating",
            date: "03 Aug 2022"
        },
        {
            image: R3,
            head: "Handmade pieces that took time to make",
            date: "03 Aug 2022"
        },
        {
            image: R4,
            head: "Modern home in Milan",
            date: "03 Aug 2022"
        },
        {
            image: R5,
            head: "Colorful office redesign",
            date: "03 Aug 2022"
        }
    ]
    const Tag = [
        {
            image: Q3,
            content: "Admin"
        },
        {
            image: Q2,
            content: "14 Oct 2022"
        },
        {
            image: Q1,
            content: "Wood"
        }
    ]

    return (
        <div>
            <div className=' aboutbanner blgban'>
                <h4 className='font-realbold'>Blog</h4>
            </div>
            <div className='container my-5'>
                <div className='row'>
                    <div className='col-lg-6' data-aos="fade-up" data-aos-delay="300">
                        <img src={B1} alt="image" />
                    </div>
                    <div className='col-lg-6 blog-sec' data-aos="fade-up" data-aos-delay="300">
                        {/* <h6 className='bl-head'>Headline</h6> */}
                        <h3 className='font-bold'>The most Popular Business Of the Year</h3>
                        <p className='bl-para0'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.</p>
                        <Link to='/blogdata' className='bl-read '>Read More</Link>
                    </div>
                </div>
            </div>
            <div className='container'>
                <div className='row gx-lg-5'>
                    <div className='col-md-8 col-12'>
                        {/* <div className='container'> */}
                        <div className='row mb-5' data-aos="fade-up" data-aos-delay="300">
                            <img src={C1} alt="computer" />
                            <div className='d-flex mt-2' >
                                {Tag.map((item, index) => {
                                    return (
                                        <div className='d-flex tag py-1'>
                                            <img src={item.image} />
                                            <p>{item.content}</p>
                                        </div>
                                    )
                                })}
                            </div>
                            <h3 className='font-bold'>Going all-in with millennial design</h3>
                            <p className='bl-para'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Mus mauris vitae ultricies leo integer malesuada nunc. In nulla posuere sollicitudin aliquam ultrices. Morbi blandit cursus risus at ultrices mi tempus imperdiet. Libero enim sed faucibus turpis in. Cursus mattis molestie a iaculis at erat. Nibh cras pulvinar mattis nunc sed blandit libero. Pellentesque elit ullamcorper dignissim cras tincidunt. Pharetra et ultrices neque ornare aenean euismod elementum.</p>
                            <Link to='/blogdata' className='bl-read '>Read More</Link>                        </div>
                        <div className='row mb-5' data-aos="fade-up" data-aos-delay="300">
                            <img src={X1} alt="computer" />
                            <div className='d-flex mt-2'>
                                {Tag.map((item, index) => {
                                    return (
                                        <div className='d-flex tag py-1'>
                                            <img src={item.image} />
                                            <p>{item.content}</p>
                                        </div>
                                    )
                                })}
                            </div>
                            <h3 className='font-bold'>Exploring new ways of decorating</h3>
                            <p className='bl-para'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Mus mauris vitae ultricies leo integer malesuada nunc. In nulla posuere sollicitudin aliquam ultrices. Morbi blandit cursus risus at ultrices mi tempus imperdiet. Libero enim sed faucibus turpis in. Cursus mattis molestie a iaculis at erat. Nibh cras pulvinar mattis nunc sed blandit libero. Pellentesque elit ullamcorper dignissim cras tincidunt. Pharetra et ultrices neque ornare aenean euismod elementum.</p>
                            <Link to='/blogdata' className='bl-read '>Read More</Link>
                        </div>
                        <div className='row' data-aos="fade-up" data-aos-delay="300">
                            <img src={X2} alt="computer" />
                            <div className='d-flex mt-2'>
                                {Tag.map((item, index) => {
                                    return (
                                        <div className='d-flex tag py-1'>
                                            <img src={item.image} />
                                            <p>{item.content}</p>
                                        </div>
                                    )
                                })}
                            </div>
                            <h3 className='font-bold'>Handmade pieces that took time to make</h3>
                            <p className='bl-para'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Mus mauris vitae ultricies leo integer malesuada nunc. In nulla posuere sollicitudin aliquam ultrices. Morbi blandit cursus risus at ultrices mi tempus imperdiet. Libero enim sed faucibus turpis in. Cursus mattis molestie a iaculis at erat. Nibh cras pulvinar mattis nunc sed blandit libero. Pellentesque elit ullamcorper dignissim cras tincidunt. Pharetra et ultrices neque ornare aenean euismod elementum.</p>
                            <Link to='/blogdata' className='bl-read '>Read More</Link>
                        </div>
                        {/* </div> */}
                    </div>
                    <div className='col-md-4' data-aos="fade-up" data-aos-delay="300">
                        <div className='recentposts'>
                            <h3 className='font-bold'>Recent Posts</h3>
                            <div className='row'>
                                <div className='col-lg-9'>
                                    {Recent.map((item, index) => {
                                        return (
                                            <div className='recent'>
                                                <img src={item.image} />
                                                <div className='d-flex flex-column'>
                                                    <p className='font-bold'>{item.head}</p>
                                                    <p>{item.date}</p>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container my-5'>
                <div className='row justify-content-center'>
                    <div className='col-lg-3 col-md-5'>
                        <div className='d-flex justify-content-around '>
                            <button className='last-butn'>1</button>
                            <button className='last-butn'>2</button>
                            <button className='last-butn'>3</button>
                            <button className='last-butn'>next</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BlogContent