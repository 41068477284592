import React from 'react'
import { Navbar } from '../Navbar/Navbar'
import { Footer } from '../Footer/Footer'

const CareerDetails = () => {
    return (
        <div>
            <Navbar />
            <div className=' aboutbanner'>
                <h4 className='font-realbold'>Career</h4>
            </div>
            <div className='topmargin'>
                <div className='container'>
                    <div className='row'>
                        <div className='col'>
                            <h1 className='text-center'>
                                UI/UX Designer
                            </h1>
                            <h4 className='fw-bold'>Job Title</h4>
                            <p>UX/UI Designer</p>
                            <h4 className='fw-bold'>Location</h4>
                            <p>OMR Chennai, Tamil Nadu, India</p>
                            <h4 className='fw-bold'>Duration</h4>
                            <p> 3 months (with potential for full-time employment)</p>
                            <h4 className='fw-bold'>Compensation</h4>
                            <p> Monthly stipend of 6K provided</p>
                            <h4 className='fw-bold'>Responsibilities</h4>
                            <ul>
                                <li>Assist in designing user interfaces and user experiences for websites and e-commerce platforms, guided by our seasoned design team</li>
                                <li>Conduct user research, create user personas, and map user journeys to guide design decisions.</li>
                                <li>Collaborate with cross-functional teams, including developers, project managers, and fellow designers, to deliver outstanding digital experiences.</li>
                                <li>Participate in design critiques, taking onboard feedback to continually improve your work.</li>
                            </ul>
                            <h4 className='fw-bold'>Requirements:</h4>
                            <ul>
                                <li> Skilled in the use of design software including Figma and Adobe XD, with a foundational grasp of the Adobe Creative Suite (e.g., Illustrator, Photoshop)</li>
                                <li> A good understanding of design principles, including typography, colour theory, and grid systems.</li>
                                <li>Knowledge of UI/UX best practices and a passion for creating user-focused designs.</li>
                                <li>A good communication and collaboration skills, with the ability to work effectively within a team.</li>
                            </ul>
                        </div>
                    </div>
                    {/* <button className='d-flex m-auto my-5 prt-btn-1'>Apply Now</button> */}
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default CareerDetails