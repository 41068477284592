import React from 'react'
import { Footer } from "../Components/Footer/Footer";
import { Navbar } from "../Components/Navbar/Navbar";
import Mvpdev from '../Components/Products/Mvpdev';


const Mvpdevpage = () => {
  return (
    <div style={{overflowX:"hidden"}}>
      <Navbar proact="active"/>
      <Mvpdev />
      <Footer />
    </div>
  )
}

export default Mvpdevpage