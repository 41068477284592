import React from 'react'
import { Footer } from "../Components/Footer/Footer";
import { Navbar } from "../Components/Navbar/Navbar";
import ProductBanner from '../Components/Products/ProductBanner';


const Product = () => {
  return (
    <div>
      <Navbar proact="active"/>
      <ProductBanner/>
      <Footer />
    </div>
  )
}

export default Product