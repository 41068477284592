import React from 'react'
import Mvp from '../../assets/images/mvp11.png'
import M from '../../assets/images/webdev.png'

const Webdev = () => {
    return (
        <div className='mvpcontent'>
            <div className='mvpbanner'>
                <img src={Mvp} alt='banner' className='w-100 bannr-img' />
                <div className='mvpcontentx'>
                    <p className='h1'>Web Application Development</p>
                    <p>Detail your expertise in developing custom web applications tailored to client needs.<br />
                        Mention technologies, programming languages, and frameworks you specialize in.</p>
                </div>
            </div>
            <div className='container'>
                <div className='row my-lg-5 my-2 mt-4'>
                    <div className='col-md-6 order-md-2 mvpblockimg' data-aos="fade-up" data-aos-delay="300">
                        {/* <div className='mvpblockimg'> */}
                        <img src={M} alt="mvpblocks" className='mvp-img' />
                        {/* </div> */}
                    </div>
                    <div className='col-md-6 order-md-1' data-aos="fade-up" data-aos-delay="300">
                        <div className='d-flex'>
                            <div className='sticker'>
                                <p className='number m-0'>1</p>
                            </div>
                            <div className='mvp-cont'>
                                <p className='intro h1 font-bold mb-4'>Frontend and Backend Development </p>
                                <ul className='mvplist'>
                                    <li className='mb-4'>Explain your capabilities in frontend (HTML, CSS, JavaScript, etc.) and backend (Node.js, Python, PHP, etc.) development.</li>
                                    <li className='mb-4'>Discuss the importance of creating responsive, user-friendly interfaces.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container'>
                <div className='row my-lg-5 my-2'>
                    <div className='col-md-6  mvpblockimg1' data-aos="fade-up" data-aos-delay="300">
                        {/* <div className='mvpblockimg'> */}
                        <img src={M} alt="mvpblocks" className='mvp-img' />
                        {/* </div> */}
                    </div>
                    <div className='col-md-6' data-aos="fade-up" data-aos-delay="300">
                        <div className='d-flex'>
                            <div className='sticker'>
                                <p className='number m-0'>2</p>
                            </div>
                            <div className=''>
                                <p className='intro h1 font-bold mb-4'>Database Design and Integration</p>
                                <ul className='mvplist'>
                                    <li className='mb-4'>Outline your proficiency in designing and integrating databases.</li>
                                    <li className='mb-4'>Emphasize data security, scalability, and efficient data management.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container'>
                <div className='row my-lg-5 my-2'>
                    <div className='col-md-6 order-md-2 mvpblockimg' data-aos="fade-up" data-aos-delay="300">
                        {/* <div className='mvpblockimg'> */}
                        <img src={M} alt="mvpblocks" className='mvp-img' />
                        {/* </div> */}
                    </div>
                    <div className='col-md-6 order-md-1' data-aos="fade-up" data-aos-delay="300">
                        <div className='d-flex'>
                            <div className='sticker'>
                                <p className='number m-0'>3</p>
                            </div>
                            <div className=''>
                                <p className='intro h1 font-bold mb-4'>API Development</p>
                                <ul className='mvplist'>
                                    <li className='mb-4'>Highlight your experience in creating robust APIs for seamless integration between different systems and platforms. </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container'>
                <div className='row my-lg-5 my-2'>
                    <div className='col-md-6  mvpblockimg1' data-aos="fade-up" data-aos-delay="300">
                        {/* <div className='mvpblockimg'> */}
                        <img src={M} alt="mvpblocks" className='mvp-img' />
                        {/* </div> */}
                    </div>
                    <div className='col-md-6' data-aos="fade-up" data-aos-delay="300">
                        <div className='d-flex'>
                            <div className='sticker'>
                                <p className='number m-0'>4</p>
                            </div>
                            <div className=''>
                                <p className='intro h1 font-bold mb-4'>Technologies</p>
                                <p className='intro h5 font-bold graytext'>Technology Stack:</p>
                                <ul className='mvplist'>
                                    <li className=''>Provide a list of the technologies, languages, and frameworks you commonly use.</li>
                                    <li className=''>Explain how your technology choices align with industry standards and client needs.</li>
                                </ul>
                                <p className='intro h5 font-bold graytext'>Innovation and Trends:</p>
                                <ul className='mvplist'>
                                    <li className=''>Demonstrate your commitment to staying updated with the latest technologies and industry trends.</li>
                                    <li className=''>Mention any innovative solutions or practices your team employs.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container'>
                <div className='row my-lg-5 my-2'>
                    <div className='col-md-6 order-md-2 mvpblockimg' data-aos="fade-up" data-aos-delay="300">
                        {/* <div className='mvpblockimg'> */}
                        <img src={M} alt="mvpblocks" className='mvp-img' />
                        {/* </div> */}
                    </div>
                    <div className='col-md-6 order-md-1' data-aos="fade-up" data-aos-delay="300">
                        <div className='d-flex'>
                            <div className='sticker'>
                                <p className='number m-0'>5</p>
                            </div>
                            <div className=''>
                                <p className='intro h1 font-bold mb-4'>Process</p>
                                <p className='intro h4 font-bold graytext'>Development Lifecycle: </p>
                                <ul className='mvplist'>
                                    <li className='mb-4'>Walk visitors through your web application development process from concept to deployment.</li>
                                    <li className='mb-4'>Explain how you collaborate with clients, gather requirements, and ensure transparent communication.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container'>
                <div className='row my-lg-5 my-2'>
                    <div className='col-md-6  mvpblockimg1' data-aos="fade-up" data-aos-delay="300">
                        {/* <div className='mvpblockimg'> */}
                        <img src={M} alt="mvpblocks" className='mvp-img' />
                        {/* </div> */}
                    </div>
                    <div className='col-md-6' data-aos="fade-up" data-aos-delay="300">
                        <div className='d-flex'>
                            <div className='sticker'>
                                <p className='number m-0'>6</p>
                            </div>
                            <div className=''>
                                <p className='intro h1 font-bold mb-4'>Testing and Quality Assurance</p>
                                <ul className='mvplist'>
                                    <li className='mb-4'>Highlight your testing and quality assurance practices to ensure bug-free and reliable applications.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container'>
                <div className='row my-lg-5 my-2'>
                    <div className='col-md-6 order-md-2 mvpblockimg' data-aos="fade-up" data-aos-delay="300">
                        {/* <div className='mvpblockimg'> */}
                        <img src={M} alt="mvpblocks" className='mvp-img' />
                        {/* </div> */}
                    </div>
                    <div className='col-md-6 order-md-1' data-aos="fade-up" data-aos-delay="300">
                        <div className='d-flex'>
                            <div className='sticker'>
                                <p className='number m-0'>7</p>
                            </div>
                            <div className=''>
                                <p className='intro h1 font-bold mb-4'>Portfolio</p>
                                <p className='intro h5 font-bold graytext'>Showcase Projects: </p>
                                <ul className='mvplist'>
                                    <li className='mb-4'>Create a comprehensive portfolio featuring a variety of web applications you've developed.</li>
                                    <li className='mb-4'>Include details about the challenges faced and how your solutions addressed them.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container'>
                <div className='row my-lg-5 my-2'>
                    <div className='col-md-6  mvpblockimg1' data-aos="fade-up" data-aos-delay="300">
                        {/* <div className='mvpblockimg'> */}
                        <img src={M} alt="mvpblocks" className='mvp-img' />
                        {/* </div> */}
                    </div>
                    <div className='col-md-6' data-aos="fade-up" data-aos-delay="300">
                        <div className='d-flex'>
                            <div className='sticker'>
                                <p className='number m-0'>8</p>
                            </div>
                            <div className=''>
                                <p className='intro h1 font-bold mb-4'>Blog/Resource Section</p>
                                <p className='intro h5 font-bold graytext'>Educational Content: </p>
                                <ul className='mvplist'>
                                    <li className='mb-4'>Share insights, tips, and industry knowledge through a blog or resource section.</li>
                                    <li className='mb-4'>Establish your company as an authority in web development.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container'>
                <div className='row my-lg-5 my-2'>
                    <div className='col-md-6 order-md-2 mvpblockimg' data-aos="fade-up" data-aos-delay="300">
                        {/* <div className='mvpblockimg'> */}
                        <img src={M} alt="mvpblocks" className='mvp-img' />
                        {/* </div> */}
                    </div>
                    <div className='col-md-6 order-md-1' data-aos="fade-up" data-aos-delay="300">
                        <div className='d-flex'>
                            <div className='sticker'>
                                <p className='number m-0'>9</p>
                            </div>
                            <div className=''>
                                <p className='intro h1 font-bold mb-4'>Testimonials</p>
                                <p className='intro h5 font-bold graytext'>Client Testimonials: </p>
                                <ul className='mvplist'>
                                    <li className='mb-4'>Display testimonials from satisfied clients.</li>
                                    <li className='mb-4'>Include specific details about the positive impact your web applications have had on their business.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Webdev