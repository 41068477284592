import React from 'react'
import office1 from '../../assets/aboutimg/office1.png'
const OfficeCard = ({country,address,ContactNumber}) => {


    return (
       <div className='card7'>
          <img src={office1} alt="image" /> 
            <div className=''>
                <h5>{country}</h5>
             {/*  <p>Lorem Ipsum is simply dummy text of the </p> */}
                <div>
                <p>{address}</p>
                    <p>{ContactNumber}</p> 
                </div>
            </div>
            </div>
           

    )
}

export default OfficeCard