import React from 'react'
import { Footer } from "../Components/Footer/Footer";
import { Navbar } from "../Components/Navbar/Navbar";
import Webdev from '../Components/Products/Webdev';

const Webdevpage = () => {
  return (
    <div style={{overflowX:"hidden"}}>
      <Navbar proact="active"/>
      <Webdev />
      <Footer />
    </div>
  )
}

export default Webdevpage