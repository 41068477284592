import React from 'react'
import { Navbar } from '../Components/Navbar/Navbar'
import { Footer } from '../Components/Footer/Footer'
import ContactContent from '../Components/Contact/ContactContent'

const Careers = () => {
    return (
        <div>
            <Navbar />
            <ContactContent />
            <Footer />
        </div>
    )
}

export default Careers