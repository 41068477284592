import { useState } from 'react'
import Team2 from '../../assets/Cardimages/team2.webp'
import axios from 'axios';
import ThankYouModal from '../Modal/ThankyouModal';

const ContactContent = () => {
    const [value, setValue] = useState({});
    const [validation, setValidation] = useState({});
    const [thankYouModal, setThankYouModal] = useState(false);

    const CheckValidation = () => {
        let Status = true,
            validdateData = {};
        if (!value.Firstname) {
            validdateData.Firstname = "First Name is Required";
            Status = false;
        }
        if (!value.Lastname) {
            validdateData.Lastname = "Last Name is Required";
            Status = false;
        }
        if (!value.Email) {
            validdateData.Email = "Email is Required";
            Status = false;
        }
        if (!value.Subject) {
            validdateData.Subject = "Subject is Required";
            Status = false;
        }
        if (!value.Message) {
            validdateData.Message = "Message is Required";
            Status = false;
        }
        if (!value.Terms) {
            validdateData.Terms = "Please agree with terms and conditions";
            Status = false;
        }
        setValidation({ ...validation, ...validdateData });
        return Status;
    };

    const handleSubmit = async (e) => {
        e.preventDefault()
        console.log(validation)
        let inputvalue = {
            Firstname: value.Firstname,
            Lastname: value.Lastname,
            Email: value.Email,
            Subject: value.Subject,
            Message: value.Message
        }

        let formdata = new FormData()
        for (let key in inputvalue) {
            formdata.append(key, inputvalue[key])
        }
        if (CheckValidation()) {
            await axios
                .post(
                    "https://script.google.com/macros/s/AKfycbws4QR-Y5SMckzinJhPecTkjlSNznM72xp2LUjsn6_99j8ukjgWRVvcRcQFf_s66lnZ/exec",
                    formdata
                )
                .then(() => {
                    setValue({});
                    setThankYouModal(true);
                    e.target.reset();
                    window.scrollTo({ top: 0, behavior: "smooth" });
                });
        }
    }


    const setData = (e, key) => {
        setValue({ ...value, [key]: e });
        if (validation[key]) setValidation({ ...validation, [key]: false });
    };

    return (
        <div>
            <ThankYouModal
                open={thankYouModal}
                setOpen={() => {
                    setThankYouModal(false);
                }}
            />
            <div className='aboutbanner conban'>
                <h4 className='font-realbold'>Contact Us</h4>
            </div>
            <div className='container'>
                <div className='row justify-content-center' data-aos="fade-up" data-aos-delay="300">
                    <div className='col-lg-8 text-center'>
                        <h2 className='font-bold'>Contact Us</h2>
                        <p className='cnt-para'>
                            At Optune Technologies our mission is to empower individuals and businesses through innovative technology solutions that enrich lives, foster growth and drive positive change.
                        </p>
                    </div>
                </div>
            </div>
            <div className='container my-5'>
                <div className='row g-3'>
                    <div className='col-lg-8'>
                        <div className='cnt-form'>
                            <form onSubmit={(e) => handleSubmit(e)}>
                                <div className='row'>
                                    <div className='col-lg-6 mb-3' data-aos="fade-up" data-aos-delay="300">
                                        <div className="">
                                            <label htmlFor="firstname" className="mb-2 fw-bold">*First Name</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="Firstname"
                                                id="firstname"
                                                onChange={(e) => {
                                                    setData(e.target.value, "Firstname");
                                                }}
                                            />
                                        </div>
                                        {validation.Firstname && (
                                            <p className="validationmsg">{validation.Firstname}</p>
                                        )}

                                    </div>
                                    <div className='col-lg-6 mb-3' data-aos="fade-up" data-aos-delay="300">
                                        <div className="">
                                            <label htmlFor="lastname" className="mb-2 fw-bold">*Last Name</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="Lastname"
                                                id="lastname"
                                                onChange={(e) => {
                                                    setData(e.target.value, "Lastname");
                                                }}
                                            />
                                        </div>
                                        {validation.Lastname && (
                                            <p className="validationmsg">{validation.Lastname}</p>
                                        )}
                                    </div>
                                    <div className='col-lg-6 mb-3' data-aos="fade-up" data-aos-delay="300">
                                        <div className="">
                                            <label htmlFor="email" className="mb-2 fw-bold">*Email</label>
                                            <input
                                                type="email"
                                                className="form-control"
                                                name="Email"
                                                onChange={(e) => {
                                                    setData(e.target.value, "Email");
                                                }}
                                                id='email'
                                            />
                                        </div>
                                        {validation.Email && (
                                            <p className="validationmsg">{validation.Email}</p>
                                        )}
                                    </div>
                                    <div className='col-lg-6 mb-3' data-aos="fade-up" data-aos-delay="300">
                                        <div className="">
                                            <label htmlFor="subject" className="mb-2 fw-bold">*Subject</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="Subject"
                                                onChange={(e) => {
                                                    setData(e.target.value, "Subject");
                                                }}
                                                id="subject"
                                            />
                                        </div>
                                        {validation.Subject && (
                                            <p className="validationmsg">{validation.Subject}</p>
                                        )}
                                    </div>
                                    <div className='col-lg-12 mb-3' data-aos="fade-up" data-aos-delay="300">
                                        <div className="">
                                            <label htmlFor="message" className="mb-2 fw-bold">*Messages</label>
                                            <textarea
                                                type="text"
                                                className="form-control"
                                                id="message"
                                                rows="5"
                                                name="Message"
                                                onChange={(e) => {
                                                    setData(e.target.value, "Message");
                                                }}
                                            />
                                        </div>
                                        {validation.Message && (
                                            <p className="validationmsg">{validation.Message}</p>
                                        )}
                                    </div>

                                    <div
                                        className='row justify-content-between mb-3'
                                        data-aos="fade-up"
                                        data-aos-delay="300">
                                        <div className='col-lg-9 d-flex flex-column'>
                                            <div className='d-flex'>
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input m-1"
                                                    id="check"
                                                    name='Terms'
                                                    onChange={(e) => {
                                                        setData(true, "Terms");
                                                    }}
                                                />{" "}
                                                <label
                                                    className="form-check-label fw-bold"
                                                    htmlFor="check"
                                                >
                                                    *I agree with terms of usage and privacy policy.
                                                </label>
                                            </div>
                                            {validation.Terms && (
                                                <p className="validationmsg">{validation.Terms}</p>
                                            )}
                                        </div>
                                        <div className='col-lg-3 text-end'>
                                            <button className='cnt-btn' type='submit'>Submit</button>
                                        </div>
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                    <div className='col-lg-4 col-12'>
                        <div className='cnt-form2'>
                            <div className='container' data-aos="fade-up" data-aos-delay="300">
                                <div className='row'>
                                    <div className='cnt-contact col-lg-12 col-md-4'>
                                        <div>
                                            <i className="fa-solid fa-phone-volume"></i>
                                        </div>
                                        <div>
                                            <h5>Call Anytime</h5>
                                            <p>India - +91 9444342144</p>
                                            <p>USA - +1 614 407 4113, +1 614 707 7875</p>
                                            {/* <p>+ 91 67678 92878</p> */}
                                        </div>
                                    </div>
                                   <div className='cnt-contact col-lg-12 col-md-4'>
                                        <div>
                                            <i className="fa-solid fa-envelope-circle-check"></i>
                                        </div>
                                        <div>
                                            <h5>Send Email</h5>
                                        <p>rshankar@optunetechnologies.com</p> 
                                           {/*   <p>hello@itfirms.com</p> */}
                                        </div>
                                    </div> 
                                    
                                   
                                   <div className='cnt-contact col-lg-12 col-md-4'>                 
                                   <div>
                                            <div className='visit'>
                                            <i className="fa-solid fa-location-dot"></i>
                                            </div>   
                                            </div>                                                                               
                                        <div>
                                            <h5>Visit Us</h5>                            
                                            <p>India - 4/410, Venture Park Building,
                                               <br></br> Semi Basement, <br></br>OMR, Thoraipakkam, 
                                            <br></br> Chennai-600097</p>
                                            <br></br>
                                            <p>USA - 9783 E 116th Street Fishers, IN 46037-2822</p> 
                                        </div>
                                    </div> 
                                </div>
                            </div>


                            {/* <div className='my-2'>
                                <h4 className='text-center fw-bold'>Follow us</h4>
                            </div>
                            <div className='cnt-logo mt-4'>
                                <a>
                                    <i className="fa-brands fa-linkedin-in"></i>
                                </a>
                                <a>
                                    <i className="fa-brands fa-instagram"></i>
                                </a>
                                <a>
                                    <i className="fa-brands fa-facebook-f"></i>
                                </a>
                                <a>
                                    <i className="fa-brands fa-x-twitter"></i>
                                </a>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className='container'>
                <div className='cnt-service' data-aos="fade-up" data-aos-delay="300">
                    <div className='row g-3'>
                        <div className='col-md-4'>
                            <i className="fa-solid fa-suitcase"></i>
                            <h5 className='font-bold'>Sales and Business</h5>
                            <p>info@optunetechnologies.com</p>
                        </div>
                        <div className='col-md-4'>
                            <i className="fa-solid fa-handshake-simple"></i>
                            <h5 className='font-bold'>Partners</h5>
                            <p>info@optunetechnologies.com</p>
                        </div>
                        <div className='col-md-4'>
                            <i className="fa-solid fa-headset"></i>
                            <h5 className='font-bold'> Customer Support</h5>
                            <p>info@optunetechnologies.com</p>
                        </div>
                    </div>
                </div>
            </div>

            <br></br>

            {/* <div className='container mt-5'>
                <div className='row justify-content-center' data-aos="fade-up" data-aos-delay="300">
                    <div className='col-lg-8 text-center'>
                        <button className='car-btn mb-3'>FAQs</button>
                        <h2 >Answers to some of your questions</h2>
                        <p>Don’t find the answers you were looking for? Contact us at <a href='' className='text-decoration-none'>futuresphere@gmail.com</a></p>
                    </div>
                </div>
                <div className='faq-details'>
                    <div className='row gx-3'>
                        <div className='col-lg-6 ' data-aos="fade-up" data-aos-delay="300">
                            <div className='faq-card'>
                                <p>What is included in the startup website template?</p>
                                <p>Our startup website template includes a range of essential features and components, such as responsive design, customizable layouts, pre-built sections, integration with popular CMS platforms, and access to a library of high-quality images and icons.</p>
                            </div>
                        </div>
                        <div className='col-lg-6' data-aos="fade-up" data-aos-delay="300">
                            <div className='faq-card'>
                                <p>Can I customize the template to match my brand's identity?</p>
                                <p>Absolutely! Our startup website template provides easy customization options, allowing you to personalize the colors, fonts, logo, can create a unique and captivating online presence that reflects your startup's vision.</p>
                            </div>
                        </div>
                    </div>
                    <div className='row gx-3'>
                        <div className='col-lg-6'>
                            <div className='faq-card' data-aos="fade-up" data-aos-delay="300">
                                <p>Is the template optimized for search engines (SEO)?</p>
                                <p>Yes, our startup website template is designed with SEO best practices in mind. It includes clean code structure, fast loading times, meta tag optimization, and schema markup implementation. These elements contribute to better search engine visibility, helping your website rank higher in search results and attract more organic traffic.</p>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='faq-card' data-aos="fade-up" data-aos-delay="300">
                                <p>Do you offer support and updates for the template?</p>
                                <p>Absolutely! We provide dedicated customer support to address any queries or concerns you may have while working with our startup website template.  latest web technologies, security patches, and feature enhancements, ensuring your website stays up-to-date and optimized.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='mb-5'>
                    <p className='text-center'>View All{" "}<span><i className="fa-solid fa-chevron-down"></i></span></p>
                </div>
            </div> */}
        </div>
    )
}

export default ContactContent