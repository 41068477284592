import React from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import { Home } from "./Pages/Home";
import About from "./Pages/About";
import Services from "./Pages/Services";
import Product from "./Pages/Product";
import Careers from "./Pages/Careers";
import Blog from "./Pages/Blog";
import Contact from './Pages/Contact'
import BlogData from "./Components/Blog/BlogData";
import ServiceDetails from "./Components/DetailedPages/ServiceDetails";
import ScrolltoTop from "./Pages/ScrolltoTop";
import CareerDetails from "./Components/DetailedPages/CareerDetails";
import Mvpdevpage from "./Pages/Mvpdevpage";
import Webdevpage from "./Pages/Webdevpage";
import Mobiledevpage from "./Pages/Mobiledevpage";
import Qualitypage from "./Pages/Qualitypage";
export const Main = () => {
  return (
    <HashRouter>
      <ScrolltoTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/AboutUs" element={<About />} />
        <Route path="/Services" element={<Services />} />
        <Route path="/Products" element={<Product />} />
        {/* <Route path="/Career" element={<Careers />} /> */}
        <Route path="/Blog" element={<Blog />} />
        <Route path="/Contactus" element={<Contact />} />
        <Route path="/blogdata" element={<BlogData />} />
        <Route path="/servicedetails" element={<ServiceDetails />} />
        <Route path="/careerdetails" element={<CareerDetails />} />
        <Route path="/mvpdevelopment" element={<Mvpdevpage />} />
        <Route path="/webdevelopment" element={<Webdevpage />} />
        <Route path="/mobiledevelopment" element={<Mobiledevpage />} />
        <Route path="/qualityanalysis" element={<Qualitypage />} />
      </Routes>
    </HashRouter>
  );
};
