import React from 'react'
import { Footer } from "../Components/Footer/Footer";
import { Navbar } from "../Components/Navbar/Navbar";
import Mobiledev from '../Components/Products/Mobiledev';


const Mobiledevpage = () => {
  return (
    <div style={{overflowX:"hidden"}}>
      <Navbar proact="active"/>
      <Mobiledev />
      <Footer />
    </div>
  )
}

export default Mobiledevpage